<template>
  <div class="pages">
    <div class="inside-nav">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        accordion
        @node-click="handleNodeClick"
      />
    </div>

    <div class="task_box">
      <!-- 顶部按钮 -->
      <div class="oper_box">
        <div class="btns" @click="handlePopups()">
          <div class="el-icon el-icon-circle-plus-outline"></div>
          <div>添加设备</div>
        </div>
        <div class="btns" @click="operDerive">
          <div class="el-icon el-icon-upload2"></div>
          <div>导&nbsp;出</div>
        </div>
      </div>
      <!-- select 筛选 -->
      <div class="select_box">
        <div class="s_item">
          <div class="s_lable">设备ID：</div>
          <div class="s_input">
            <el-input v-model="queryInfo.name" placeholder="设备ID" />
          </div>
        </div>
        <div class="s_item">
          <div class="s_lable">客户名称：</div>
          <div class="s_input">
            <el-input v-model="queryInfo.customer" placeholder="客户名称" />
          </div>
        </div>
        <div class="s_item">
          <div class="s_lable">设备状态：</div>
          <el-select v-model="queryInfo.status" placeholder="请选择设备状态">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="btns" @click="getFiltrateList">查询</div>
      </div>
      <!-- 数据表格 -->
      <div class="table_boxs">
        <el-table
          ref="multipleTable"
          :data="tableList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
        >
          <el-table-column
            align="center"
            prop="equip_no"
            label="设备ID"
            width="150"
          />
          <el-table-column
            align="center"
            prop="status_text"
            label="设备状态"
            width="150"
          />
          <el-table-column
            align="center"
            prop="website.name"
            label="所属组织"
            min-width="90"
          />
          <el-table-column
            align="center"
            prop="wechat_location"
            label="安装位置"
            width="150"
          />
          <el-table-column
            align="center"
            prop="cable"
            label="图片展示"
            min-width="80"
          >
            <template slot-scope="scope">
              <img :src="scope.row.cable.image[0]" min-width="40" height="40" />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="280"
          >
            <template slot-scope="scope">
              <div class="handle_box">
                <div class="handle_btn edit" @click="jiance(scope.row.id)">
                  监测
                </div>
                <div
                  class="handle_btn edit"
                  @click="handlePopups(scope.row.id)"
                >
                  详情
                </div>
                <div
                  class="handle_btn edit"
                  @click="handlePopups(scope.row.id)"
                >
                  编辑
                </div>
                <div class="handle_btn" @click="handleDelete(scope.row.id)">
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="queryInfo.page"
          next-text="下一页"
          prev-text="上一页"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!-- 监测 -->
    <el-dialog title="提示" :visible.sync="dialogjian" width="52%">
      <div id="mains" class="mains"></div>
    </el-dialog>

    <!-- 添加设备弹出层 -->
    <el-dialog
      :title="addPopupsTitle"
      :visible.sync="dialogFormVisible"
      width="500px"
    >
      <el-form :model="addInfo">
        <el-form-item label="设备ID:" label-width="120px">
          <el-input v-model="addInfo.equip_no" autocomplete="off" />
        </el-form-item>
        <el-form-item label="设备名称" label-width="120px">
          <el-input v-model="addInfo.name" autocomplete="off" />
        </el-form-item>
        <el-form-item label="所属组织:" label-width="120px">
          <el-cascader
            v-model="addInfo.website_id"
            :options="organizeList"
            :show-all-levels="false"
            :props="customProps"
            @change="getProjectVal"
          />
        </el-form-item>
        <el-form-item label="线路总称：" label-width="120px">
          <el-input v-model="addInfo.line_name" autocomplete="off" />
        </el-form-item>
        <el-form-item label="进线头名称：" label-width="120px">
          <el-input v-model="addInfo.inletwire_name" autocomplete="off" />
        </el-form-item>

        <!-- <el-form-item label="设备类型:" label-width="120px">
          <el-select v-model="addInfo.device_type" placeholder="请选择">
            <el-option
              v-for="item in device_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="出线接头名称:" label-width="120px">
          <el-input v-model="addInfo.outgoingline_name" autocomplete="off" />
        </el-form-item>

        <el-form-item label="线直径大小：" label-width="120px">
          <el-input v-model="addInfo.line_diameter" autocomplete="off" />
        </el-form-item>
        <el-form-item label="线缆铭牌号：" label-width="120px">
          <el-input v-model="addInfo.cable_brand" autocomplete="off" />
        </el-form-item>

        <el-form-item label="安装位置：" label-width="120px">
          <el-input v-model="addInfo.wechat_location" autocomplete="off" />
        </el-form-item>
        <el-form-item label="安装照片：" label-width="120px">
          <!-- <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img class="avatar" width="100%" :src="dialogImageUrl" alt="">
              </el-dialog> -->
          <el-upload
            class="avatar-uploader"
            action="
https://api.cdksense.com/api/other/uploadImage"
            :show-file-list="false"
            name="image"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="addInfo.imageUrl"
              :src="addInfo.imageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFacility()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      dialogjian: false,
      device_list: [
        {
          value: '1',
          label: '开闭站',
        },
        {
          value: '2',
          label: '电缆',
        },
        {
          value: '3',
          label: '其他设备',
        },
      ],
      id: 1,
      dialogVisible: false,
      customProps: {
        children: 'children',
        value: 'id',
        label: 'title',
      },
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title',
      },

      // 筛选 type select中的 列表数据
      typeList: [
        { value: 1, label: '在线' },
        { value: 2, label: '离线' },
      ],
      // 查询参数对象
      queryInfo: {
        name: '',
        organize_id: '',
        status: '',
        page: 1,
        limit: 10,
        customer: '',
      },
      // 表格列表
      tableList: [],
      // 总数据条数
      total: 0,

      // 添加设备
      addInfo: {
        wechat_location: '',
        equip_no: '',
        inletwire_name: '',
        outgoingline_name: '',
        line_diameter: '',
        cable_brand: '',
        line_name: '',
        place: '',
        website_id: '',
        device_type: '',
        id: '',
        status: '',
        name: '',
        type: '',
        image: '',
        imageUrl: '',

        electricity_meter_id: '',
        description: '',
      },
      // 添加修改弹出层标题
      addPopupsTitle: '',
      // 弹出层开启与隐藏
      dialogFormVisible: false,
      // 添加中 项目列表
      organizeList: [],
      organize: [],
    }
  },
  mounted() {
    // 获取左侧二级菜单的数据
    this.getTreeTitleList()
    // 获取表格列表数据
    this.getTableList()
    // 获取 筛选类型选择项列表
    this.getTypeList()
    // 页面初始化 获取 添加数据中的 组织项目列表
    this.getOrganizeList()
  },
  methods: {
    async jiance(id) {
      const res = await this.$http.post('Applet/cable_chart', { id })
      console.log(res)
      if (res.code == 1) {
        this.dialogjian = true
        this.$nextTick(() => {
          this.echat(res.data)
        })
      }
    },
    echat(data) {
      var chartDom = document.getElementById('mains')
      console.log(chartDom, 'qqq')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: data.title,

          textStyle: {
            fontSize: 17,
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside', // 1平移 缩放
            throttle: '50', // 设置触发视图刷新的频率。单位为毫秒（ms）。
            minValueSpan: 6, // 用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
            start: 1, // 数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
            end: 50, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
            zoomLock: true, // 如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
          },
        ],
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.date,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: data.title[0],
            type: 'line',
            // stack: 'Total',
            data: data.temperature,
          },
          {
            name: data.title[1],
            type: 'line',
            // stack: 'Total',
            data: data.environment,
          },
          {
            name: data.title[2],
            type: 'line',
            // stack: 'Total',
            data: data.pv,
          },
          {
            name: data.title[3],
            type: 'line',
            // stack: 'Total',
            data: data.mv,
          },
          {
            name: data.title[4],
            type: 'line',
            // stack: 'Total',
            data: data.df,
          },
        ],
      }

      option && myChart.setOption(option)
    },

    handleAvatarSuccess(res, file) {
      this.addInfo.imageUrl = URL.createObjectURL(file.raw)
      this.addInfo.image = res.data.img_link
    },

    handleRemove() {},

    // 获取左侧二级菜单的数据
    async getTreeTitleList() {
      const res = await this.$http.get('Organize/left')
      console.log(res)
      if (res.code !== 1) return this.$message.error('获取组织列表失败！')
      // this.$message.success('获取设备列表成功！')
      this.treeData = res.data
    },
    // 获取 表格列表数据
    async getTableList() {
      const res = await this.$http.post('device/cable', this.queryInfo)
      if (res.code !== 1) return this.$message.error('获取设备列表失败！')
      // this.$message.success('获取设备列表成功！')
      this.tableList = res.data.list
      this.total = res.data.total
    },

    // 分页切换 获取列表数据
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getTableList()
    },

    // 获取 筛选select中类型数据
    async getTypeList() {
      const res = await this.$http.post('other/device_type')
      this.typeList = res.data
    },

    // 筛选查询
    getFiltrateList() {
      this.total = 0
      this.queryInfo.page = 1
      this.getTableList()
    },

    // 获取项目组织列表
    async getOrganizeList() {
      const res = await this.$http.post('Organize')
      this.organizeList = res.data
    },

    // 获取 设备详情
    async getFacilityDetail(id) {
      const res = await this.$http.post('device/info', { device_id: id })
      if (res.code !== 1) return this.$message.error('设备数据获取失败！')
      console.log(res, 'tyuu')
      this.addInfo.id = id
      // this.addInfo.organize_id = res.data.organize_id

      // this.addInfo.type = res.data.type
      this.addInfo.place = res.data.place
      this.addInfo.equip_no = res.data.equip_no
      // this.addInfo.description = res.data.description
      // this.addInfo.electricity_meter_id = res.data.electricity_meter_id
      this.addInfo.name = res.data.name
      this.addInfo.inletwire_name = res.data.cable.inletwire_name
      this.addInfo.outgoingline_name = res.data.cable.outgoingline_name
      this.addInfo.line_diameter = res.data.cable.line_diameter
      this.addInfo.cable_brand = res.data.cable.cable_brand
      this.addInfo.line_name = res.data.cable.line_name
      this.addInfo.website_id = res.data.website_id
      this.addInfo.wechat_location = res.data.wechat_location
      this.addInfo.image = res.data.cable.image
      if (res.data.cable.image == null) {
        this.addInfo.imageUrl = ''
      } else {
        this.addInfo.imageUrl = res.data.cable.image[0]
      }

      console.log(this.addInfo)
    },

    // 打开 添加/修改 设备数据
    handlePopups(id) {
      if (id == undefined) {
        this.addPopupsTitle = '添加设备'
        this.addInfo = {
          equip_no: '',
          inletwire_name: '',
          outgoingline_name: '',
          line_diameter: '',
          cable_brand: '',
          line_name: '',
          place: '',
          website_id: '',
          device_type: '',
          image: '',
          imageUrl: '',

          id: '',
          status: '',
          name: '',
          type: '',

          electricity_meter_id: '',
          description: '',
        }
        this.dialogFormVisible = true
      } else {
        this.addPopupsTitle = '修改设备'
        this.getFacilityDetail(id)
        this.dialogFormVisible = true
      }
    },

    // 选择 组织/所属项目
    getProjectVal(e) {
      // 清除选择 修改 id为空
      if (e.length == 0) return (this.addInfo.organize_id = '')
      console.log(e, '获取 选中后的最后一个值')
      // console.log(e.slice(-1)[0], "获取 选中后的最后一个值")
      // 获取 选中后的最后一个值
      // this.addInfo.organize_id = e.slice(-1)[0]
      this.addInfo.website_id = e.slice(-1)[0]
    },

    // 提交添加设备数据
    async addFacility() {
      // if(this.addInfo.organize_id == '') return this.$message.error('请选择所属项目！')
      // if(this.addInfo.type == '') return this.$message.error('请选择用电类型！')
      // if(this.addInfo.name == '') return this.$message.error('请输入设备名称！')
      // if(this.addInfo.place == '') return this.$message.error('请输入安装位置！')
      // if(this.addInfo.equip_no == '') return this.$message.error('请输入设备编号！')
      // if(this.addInfo.electricity_meter_id == '') return this.$message.error('请输入电表ID！')

      if (this.addInfo.equip_no == '') {
        return this.$message.error('请输入设备编号！')
      }
      if (this.addInfo.inletwire_name == '') {
        return this.$message.error('请输入进线接头名称')
      }
      if (this.addInfo.outgoingline_name == '') {
        return this.$message.error('请输入出线接头名称')
      }
      if (this.addInfo.line_diameter == '') {
        return this.$message.error('请输入线直径大小')
      }
      if (this.addInfo.cable_brand == '') {
        return this.$message.error('请输入线缆铭牌号')
      }
      if (this.addInfo.line_name == '') {
        return this.$message.error('请输入线路总称')
      }
      if (this.addInfo.wechat_location == '') {
        return this.$message.error('请输入安装位置！')
      }

      const res = await this.$http.post('device/handle', this.addInfo)
      if (res.code !== 1) return this.$message.error('设备添加失败！')
      this.$message.success('设备添加成功！')
      // 设备添加成功后要更新表格数据
      this.getTableList()
      this.dialogFormVisible = false
    },

    // 左侧二级菜单
    handleNodeClick(data) {
      console.log(data, '左侧二级菜单')
      if (data.id) {
        this.queryInfo.organize_id = data.id
        this.getFiltrateList()
      }
    },

    // 删除
    handleDelete(id) {
      this.$confirm('您确定要删除此账号吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async() => {
          const res = await this.$http.post('device/del', { device_id: id })
          if (res.code !== 1) return this.$message.error('设备删除失败！')
          this.$message.success('设备删除成功！')
          // 设备添加成功后要更新表格数据
          this.getTableList()
        })
        .catch((e) => {
          if (e == 'cancel') return this.$message.info('已取消删除')
        })
    },

    // 导出
    operDerive() {
      window.location.href =
        'http://211.149.176.35:9001/api/exports/device?organize_id=' +
        this.queryInfo.organize_id +
        '&name=' +
        this.queryInfo.name +
        '&type=' +
        this.queryInfo.type
    },
  },
}
</script>
<style lang="scss" scoped>
.mains {
  width: 800px;
  height: 500px;
}
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
  display: block;
}
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: space-between;
}
// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  background: rgba(35, 59, 103, 0.67);
  border-radius: 6px;
  border: 1px solid #4486ff;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background: rgba(35, 59, 103, 0.67);

  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
// ::v-deep .el-tree-node__content:hover,
// ::v-deep .el-upload-list__item:hover {
//   background-color: #1b284d;
//   color: #68d8fe;
// }

// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #4486ff !important;
  }
  // 顶部按钮
  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background: #233b67;
      border-radius: 5px;
      border: 1px solid #4486ff;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  // select 筛选
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #233b67;
      border-radius: 5px;
      border: 1px solid #4486ff;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.table_boxs::v-deep {
  margin-top: 20px;
  // padding: 20px 0;
  position: relative;

  // tab表格样式
  .el-table {
    width: 100%;
  }

  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #32477d;
  }
  .el-table tr {
    background-color: #121d3c;
  }
  .el-table {
    color: #ffffff;
  }
  .el-table thead {
    color: #ffffff;
  }
  .el-tabs__content {
    overflow: unset !important;
  }
  .el-table-column--selection {
    padding: 16px 10px !important;
  }
  .el-table .has-gutter .el-table__cell,
  .el-table th.el-table__cell {
    background-color: #2c5092;
  }
  .el-table .el-table__cell {
    // padding: 16px 0;
    border: 0;
  }
  .el-table::before,
  .el-table__fixed-right::before {
    height: 0;
  }
  tr.el-table__row--striped td.el-table__cell {
    background-color: #213058;
  }
  .handle_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .handle_btn {
      height: 26px;
      line-height: 26px;
      width: 50px;
      border-radius: 5px;
      border: 1px solid #61cdf1;
      font-size: 13px;
      cursor: pointer;
      margin-right: 15px;
    }
    .edit {
      background-color: #61cdf1;
    }
  }
  // 分页
  .el-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pager li {
      font-size: 12px;
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #2c5092;
      padding: 0;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      margin: 0 7px;
    }
    .btn-next,
    .btn-prev {
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #2c5092;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
    .btn-next span,
    .btn-prev span {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
    }
  }
}
.el-form .el-select,
.el-form .el-cascader {
  width: 100%;
}
</style>
